<template>
  <main class="HubControl">
    <TheSubHeader
      icon="address"
      title="Controle de HUB"
      page-title-tag="Hubs"
      type="filter"
      :actions="['back']"
      organizer-gap="1rem"
      organizer-columns="3fr 3fr 1fr"
    >
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.hubControl.addNewButton"
          icon="add"
          color="primary-base"
          label="Cadastrar HUB"
          :track="trackSubheaderButtonString('Hubs', 'Add New Hub')"
          @click="goToNewHub"
        />
      </template>
      <template #filter>
        <!-- Hubs -->
        <BaseTreeSelect
          v-model="filters.id"
          name="HUBs"
          label="HUBs"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchHubsAsync'"
          @input="updateTags()"
        />
        <!-- Stores -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.stores"
          name="Lojas"
          label="Lojas"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="updateTags()"
        />
        <!--Search -->
        <BaseButton
          label="Filtrar"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          @click="search()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="HubControl__Content">
      <div class="HubControl__Table">
        <DataTable
          :header="transitPoints.data.headers"
          :table="transitPoints.data.content"
          is-sticky
          @edit="editTransitPoint"
          @remove="confirmRemoveTransitPoint"
          @showStores="previewTransitPointStores"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>
    <Dialog
      v-if="showConfirmDialog"
      :warning="confirmDialogMsg"
      label-cancel="Não"
      label-confirm="Sim"
      :dialog-action="confirmDialogAction"
      @confirm="confirmDialog"
      @cancel="cancelDialog"
    />
    <Modal v-if="showPreviewTransitPointStores" @close="showPreviewTransitPointStores = false">
      <template #header>
        <div class="HubControl__modalHeader">
          Nome do HUB: {{ showPreviewTransitPointStoresTitle }}
        </div>
      </template>
      <template #body>
        <div class="HubControl__modal">
          <DataTable
            :header="showPreviewTransitPointStoresTable.data.headers"
            :table="showPreviewTransitPointStoresTable.data.content"
          />
        </div>
      </template>
    </Modal>
  </main>
</template>

<script>
import { routes } from '@/api'
import { BaseTreeSelect, BaseButton } from '@/components/atoms'
import { Pagination, DataTable, FiltersTag, Dialog, Modal } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { mapActions, mapState, mapGetters} from 'vuex'

export default {
  name: 'HubControl',
  components: {
    TheSubHeader,
    DataTable,
    Pagination,
    BaseTreeSelect,
    BaseButton,
    FiltersTag,
    Dialog,
    Modal
  },
  
  data() {
    return {
      filters: {
        id: [],
        stores: []
      },
      allTags: [],
      confirmDialogMsg: '',
      confirmDialogAction: '',
      showConfirmDialog: false,
      idTransitPoint: 0,
      showPreviewTransitPointStores: false,
      showPreviewTransitPointStoresTitle: '',
      showPreviewTransitPointStoresTable: {
        data: {
          headers: {
            'id':{
              'id':'id',
              'param':'id',
              'label':'Id',
              'method':false,
              'isVisible':false
            },
            'name':{
              'id':'name',
              'param':'name',
              'label':'Lojista',
              'method':false,
              'isVisible':true
            },
            'parent.name':{
              'id':'parent.name',
              'param':'parent.name',
              'label':'Lojista Principal',
              'method':false,
              'isVisible':true
            },
            actions: {}
          },
          content: []
        }
      }
    }
  },
  
  computed: {
    ...mapState({
      storesImport: state => state.stores.storesImport,
      transitPoints: state => state.routes.transitPoints,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      permissions: state => state.auth.permissions
    }),

    ...mapGetters({
      storesByGroup: 'stores/storesByGroup'
    }),
  },
  mounted() {
    this.fetchStores()
    this.search()
  },
  
  methods: {
    ...mapActions({
      setParameters: 'queryString/setParameters',
      fetchTransitPoints: 'routes/fetchTransitPoints',
      deleteTransitPoint: 'routes/deleteTransitPoint',
      fetchStores: 'stores/fetchStores',
      setPagination: 'pagination/setPagination',
      setLoading: 'loading/setLoading',
    }),

    updateTags(){
      this.allTags = [...this.filters.id, ...this.filters.stores]
    },

    editTransitPoint(data) {
      this.$router.push({
        path: `/routes/hub/${data.id}`
      })
    },
    
    confirmRemoveTransitPoint(data) {
      this.idTransitPoint = data.id
      this.confirmDialogMsg = 'Tem certeza que deseja deletar esse HUB?'
      this.confirmDialogAction = 'removeOrder'
      this.showConfirmDialog = true
    },

    confirmDialog() {
      if (this.confirmDialogAction === 'removeOrder') {
        this.deleteTransitPoint({ id: this.idTransitPoint })
      }
      this.showConfirmDialog = false
    },

    cancelDialog(){
      this.showConfirmDialog = false
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.id = this.filters.id.filter((tag) => tag.name !== name)
      this.filters.stores = this.filters.stores.filter((tag) => tag.name !== name)
    },

    clearAllTags(){
      this.allTags = []
      this.filters.id = []
      this.filters.stores = []
    },

    captureParentStore(parentID){
      let parentName =  this.storesByGroup.filter((store) => {
        return store.id === parentID
      })
      return parentName[0].name
    },

    async previewTransitPointStores(el) {
      this.setLoading(true)
      this.showPreviewTransitPointStoresTable.data.content = []
    
      await routes.fetchTransitPoint({ id:el.id }, (r) => {
        this.showPreviewTransitPointStoresTitle = `${el.name}`
        this.showPreviewTransitPointStores = true
        for (let index = 0; index < r.stores.length; index++) {
          this.showPreviewTransitPointStoresTable.data.content.push({
            'id' : r.stores[index].id,
            'name' : r.stores[index].name,
            'parent.name' : this.captureParentStore(r.stores[index].parent_id)
          })
        }
      }, (e) => e)
      this.setLoading(false)
    },

    async search(){
      this.$nextTick(async () => {
        const params = {
          hubs: {
            name: this.filters.id,
          },
          stores: {
            'filter[stores][store_id]': this.$refs.lojista.checkSelectAll ? [] : this.filters.stores,
          },
          paginate: this.paginate,
          page: this.page
        }
        await this.updateTags()
        await this.setParameters(this.filters)
        await this.fetchTransitPoints(params).then(() => this.setPagination(this.transitPoints))

      })
  
    },

    goToNewHub() {
      this.$router.push('/routes/hub/add')
    }

  }
}
</script>
<style lang="scss" scoped>
.HubControl{
    &__Content{
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }
  &__Table{
    grid-area: main;
    margin-bottom: 50px;
  }
  &__modalHeader{
    padding-top: 5px;
    padding-left: 10px;
  }
 
}
</style>
<style lang="scss">
.HubControl{
  &__TheSubheader--btn {
    margin-left: 1rem;
  }
  .Modal__container{
      width: 65%;
      height: 70%;
       @media (max-width: $viewport-sm) {
     width: 90%;
      height: 90%;
  }
    }
  }
</style>